
.hide-notifications {
  .iziToast-wrapper {
    display: none!important;
  }
}

.external {
  .iziToast-wrapper {
    display: none!important;
  }
}


.iziToast-wrapper {
  z-index: 5;
}

.iziToast-capsule {
  box-shadow: none;
  cursor: pointer;
}

.iziToast {
  cursor: pointer;
  border-radius: 0;
  box-shadow: none;
  font-family: "Roboto", Tahoma, Arial;
}
.iziToast:after {
  box-shadow: none !important;
}


.iziToast-wrapper-topRight {
  padding: 0;
  top: 3.2em;
  right: 3em;
  .iziToast-title {
    display: block;
    float: none !important;
  }

  
  
}

.iziToast-wrapper-topCenter  {
  top: 3.2rem;
  left: .25rem;
  padding-right: 2.5rem!important;
  z-index: 1;
}

.map-full-screen {
  .iziToast-wrapper{
    top: .25rem!important;
  }
}
