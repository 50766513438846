@use "~bootstrap/scss/bootstrap.scss";

.attributes-modal {
  .attribute {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    border-bottom: 1px solid bootstrap.$secondary;
    padding-right: 5em;
    position: relative;
    &:last-child{
        border-bottom: 0;
    }

    .name {
      font-weight: bold;
      margin-right: 1em;
      min-width: 170px;
    }

    .value {
      .attr-color{
        display: inline-block;
      }
    }

    .controls{
        position: absolute;
        right: 0;
        width: 5em;
        display: flex;
        .btn{
            padding: 0 .125em;
            margin: 0;
            height: 1.5em;
            margin-left: .3em;
            box-shadow: none!important;
        }
    }
  }
}
