.md-margin {
  .btn {
    margin-top: 0 !important;
    margin-left: 1rem !important;
    margin-right: 0 !important;
    height: 2rem !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0.8rem !important;
    padding-right: 0.8rem !important;
  }
}

.md-pd-btn {
  margin-top: 0 !important;
  margin-left: 1rem !important;
  margin-right: 0 !important;
  margin-bottom: 0!important;
  height: 2rem !important;
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important;
}