@use "~bootstrap/scss/bootstrap.scss";
@use "../variables" as variables2;


.app-sidebar {
  position: fixed;
  bottom: 0;
  width: calc(100% - 30px);
  max-width: 350px;
  top: 2.95rem;
  border: none;
  background: bootstrap.$white;
  z-index: 3;
  display: flex;
  flex-direction: column;
  height: calc(100% - 2.95rem);
  transition: transform 0.2s ease-in;
  transform: translateX(-100%);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;

  > .content {
    display: flex;
    flex-direction: column;
    background: bootstrap.$white;
    z-index: 1;
    border-right: 1px solid bootstrap.$border-color;
    max-height: 100%;
    height: 100%;
  }

  .nav-link {
    padding: 0.5rem;
    font-size: 0.8em;
    &.active {
      border-bottom: 3px solid bootstrap.$primary !important;
    }
  }

  .tab-content {
    max-height: 100%;
    height: calc(100% - 3rem);
    display: flex;
    flex-direction: column;
    .tab-pane {
      max-height: 100%;
      height: 100%;
      .tab-content-wrapper {
        max-height: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        > .content {
          //overflow: scroll;         
          //overflow-y: scroll;
          overflow: auto;
          max-height: 100%;
          height: 100%;
        }
      }
    }
  }

  .filter {
    .form-label {
      font-weight: bold;
    }
    font-size: 0.7em;
    .form-control {
      font-size: 1em !important;
    }
    &.lg {
      font-size: 1em !important;
    }
  }

  .reports-config {
    .form-label {
      font-weight: bold;
    }
    font-size: 0.7em;
    .filter {
      font-size: 0.7em;
      .form-control {
        font-size: 1em !important;
      }
    }

    .period {
      .form-control {
        position: relative;
        font-size: 1em !important;
      }
      .rdtOpen {
       .rdtPicker{
         //margin-top: -100%;
       }
        /*.rdtDays {
          top: -270px;
        }
        .rdtTime {
          top: -250px;
        }*/
      }
    }
  }

  .overlay {
    position: fixed;
    display: none;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(variables2.$dark-bg, 0.5);
    width: 200vw;
  }

  .control {
    font-size: 1.5em;
    position: fixed;
    height: 3em;
    width: 1.5em;
    right: -1.5em;
    margin-right: 1px;
    top: -1px;
    background: bootstrap.$white;
    border-radius: 0 0 5px 0;
    line-height: 3em;
    text-align: center;
    border: 1px solid bootstrap.$border-color;
    border-width: 1px 1px 1px 0;
    opacity: 1;
    cursor: pointer;
    transition: all 0.2s ease-in;
    box-shadow: 0 0.1rem 0.1.5rem rgba(0, 0, 0, 0.075) !important;

    top: 30%;
    margin-top: -1.5em;
    border-radius: 0 5px 5px 0;

    .button:before {
      content: "\F0142";
      font-family: "Material Design Icons";
      opacity: 0.8;
    }

    &:hover {
      /*width: 1.5em;
      right: -1.5em;*/
      .button:before {
        opacity: 1;
      }
    }
  }

  nav {
    display: flex;
    flex-grow: 0;
    font-size: 1em;
    font-weight: 400;
    height: 2.75rem;
    text-transform: uppercase;
    .nav-item {
      border-radius: 0;
    }

    .nav-link.active,
    .nav-link.active:hover,
    .nav-item.show .nav-link {
      border-color: transparent #dee2e6 transparent;

      &:last-child {
        border-right-color: transparent;
      }
    }

    .nav-link:hover {
      border-color: transparent;
    }
  }

  //state
  &.toggled {
    transform: translateX(0);
    .control {
      .button:before {
        content: "\F0141";
        font-family: "Material Design Icons";
        opacity: 0.8;
      }
    }

    .overlay {
      display: block;
      opacity: 1;
    }
  }

  @media screen and (min-width: variables2.$mobile-break) {
    transform: translateX(0);
    .control {
      .button:before {
        content: "\F0141";
        font-family: "Material Design Icons";
        opacity: 0.8;
      }
    }
    &.toggled {
      transform: translateX(-100%);
      .control {
        .button:before {
          content: "\F0142";
          font-family: "Material Design Icons";
          opacity: 0.8;
        }
      }

      .overlay {
        display: none;
        opacity: 1;
      }
    }
  }
}
