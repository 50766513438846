@use "~bootstrap/scss/bootstrap.scss";
@use "variables" as variables2;

.device-status {
  font-size: 1.3em;
  position: relative;
  top: 1px;
  display: block;
  float: right;
  color: variables2.$secondary;
  margin-left: 0.5em;
  transition: color 0.5s linear;
  width: 1em;

  &.online-status {
    &.online {
      color: variables2.$online;
    }
    &.offline {
      color: variables2.$offline;
    }
    &.unknown {
      color: variables2.$unknown;
    }
  }

  &.ignition-status {
    &.on {
      color: variables2.$online;
    }
    &.off {
      color: variables2.$offline;
    }
    &.unknown {
      color: variables2.$unknown;
    }
  }

  &.motion-status {
    &.moving {
      color: variables2.$online;
    }
    &.stopped {
      color: variables2.$offline;
    }
    &.unknown {
      color: variables2.$unknown;
    }
  }
  &.speed-status {
    &.moving {
      color: variables2.$online;
    }
    &.stopped {
      color: variables2.$offline;
    }
    &.unknown {
      color: variables2.$unknown;
    }
  }
  &.odometer-status {
    color:bootstrap.$black;
    &.moving {
      color: variables2.$online;
    }
    &.stopped {
      color: variables2.$offline;
    }
    &.unknown {
      color: variables2.$unknown;
    }
  }
}
