@use "../variables";

.appLoader {
  position: fixed;
  z-index: 999;
  background: variables.$black-bg;
  background-image: url("/img/logo-light.svg"), url("/img/loader-01.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: calc(50%) calc(50% + 20px), calc(50%) calc(50% - 75px);
  background-size: 300px, 100px;
  font-weight: 300;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
  z-index: 100;
  transition: opacity 0.5s ease-in;
  &.hidden {
    opacity: 0;
    display: none !important;
  }
}
