@use "sass:color";
@use "~bootstrap/scss/bootstrap.scss";
@use "../variables" as variables2;

/* The container */
.custom-chk {
  display: block;
  position: relative;
  padding-left: 1.5em;
  margin-bottom: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-width: 1.5em;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    font-size: 2em;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0.1em;
    left: 0;
    height: 1.2em;
    width: 1.2em;
    background-color: color.adjust(variables2.$secondary, $lightness: -20);
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: color.adjust(variables2.$secondary, $lightness: -30);
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: bootstrap.$primary;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    font-size: 0.8em;
    left: 0.55em;
    top: 0.1em;
    width: 0.5em;
    height: 1em;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
