@use "~bootstrap/scss/bootstrap.scss";
@use "../variables" as variables2;

.map-wrapper {
  min-height: 100%;
  height: 100%;
  .map {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: bootstrap.$gray-300;
    z-index: 0;
    > div {
      min-height: 100%;
      height: 100%;
    }
  }

  .leaflet-container {
    z-index: 0;
  }
}

.leaflet-map-pane canvas{
  z-index: 99999;
}

.leaflet-popup-content-wrapper {
  border-radius: 15px;
  min-width: 340px;
  padding: .5em 0 .5em;
  margin: 0;
  .map-popup{
    min-width: 300px;
    padding: .5em;
    .title{
      font-size: 1.4em;
      border-bottom: 1px solid bootstrap.$border-color;
    }
    .attributes{
      width: 100%;
      max-height: 270px;
      overflow-y:auto;
      tr{
        border-bottom: 1px solid bootstrap.$border-color;
      }
    }
  }
}
.leaflet-control-locate {
    margin-right: -20px;
    right: -0.7rem ;
    bottom: 5rem;
    
}

.leaflet-bar a:hover {
  background-color: rgba(0, 0, 0, 0.87);

  
  color: #fafafa;
}
.leaflet-bar a,
{
  background-color: #fafafa;

  color: rgba(0, 0, 0, 0.87);
  
}


.map-controls {
  background: transparent;
  width: 2rem;
  position: fixed;
  right: 0;
  top: 3.5rem;
  z-index: 2;
  transition: all 0.5s ease-in;

  .section {
    margin-bottom: 1em;
  }

  .dropdown-toggle {
    &:before {
      display: none;
    }
  }

  .btn.btn-sm,
  .dropdown-toggle {
    width: 2rem;
    height: 2rem;
    padding: 0;
    margin: 0;
    line-height: 1.9rem;
    font-size: 1.2rem;
    border: 1px solid bootstrap.$border-color;
  }

  .btn.active {
    background: bootstrap.$primary !important;
    color: bootstrap.$white !important;
    border-color: bootstrap.$primary !important;
  }

 

  @media screen and (min-height: variables2.$mobile-break) {
    top: 5rem;
  }
}
