@use "sass:color";
@use "~bootstrap/scss/bootstrap.scss";
@use "../variables" as variables2;

.device-groups {
  .device-groups-item {
    border-bottom: 1px solid bootstrap.$border-color;
    

    &:last-child {
      border-bottom: none;
    }

    

    .header {
      background: variables2.$secondary;
      padding: 0.5rem 1rem;
      padding-right: 4rem;
      cursor: pointer;
      position: relative;
      border-bottom: 1px solid color.adjust(variables2.$secondary, $lightness: -10%);
      &:after {
        content: "";
        clear: both;
        display: block;
      }
      &:before {
        content: "\F0415";
        font-family: "Material Design Icons";
        display: block;
        position: absolute;
        width: 1em;
        height: 1em;
        font-weight: bold;
        right: 0.5em;
        top: 0.125em;
        line-height: 1em;
        font-size: 1.5em;
      }
      .edit {
        position: absolute;
        right: 0em;
        top: 0.30em;
        line-height: 1em;
        font-size: 1.2em;
        opacity: 0;
      }
      .title {
        float: left;
        font-size: 0.9em;
        line-height: 1em;
        margin: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &:hover{
        .edit{
          opacity: 1;
        }
      }
    }

    .content {
      height: 0 !important;
      overflow: hidden !important;
      
    }

    &.expanded {
      .content {
        height: 100% !important;
        overflow: auto !important;
        transition: none;
      }
      .header {
        &:before {
          content: "\F0374";
        }
      }
    }
  }
}
