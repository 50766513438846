
//.btn {
//  margin-left:  -0.375rem !important;
//}

.btn-group-vertical>.btn-group:not(:first-child), .btn-group-vertical>.btn:not(:first-child) {
  margin-top: 1px;
  margin-left: -0.375rem !important;
}

.left-margin {
  margin-left: -0.375rem !important;
}

.btn-light:not([disabled]):not(.disabled):active, .btn-light:not([disabled]):not(.disabled).active, .show>.btn-light.dropdown-toggle {
  background-color: rgba(1, 160, 226, 1) !important
}