@use "~bootstrap/scss/bootstrap.scss";
@use "../variables" as variables2;

.device-list {
  @keyframes opacity {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.75;
    }
    100% {
      opacity: 1;
    }
  }

  .list-item {
    padding: 0;
    .device {
      padding: 0.5em 1em;
      cursor: pointer;
      font-size: 0.8em;
      &:after {
        content: "";
        clear: both;
        display: block;
      }
      .content {
        overflow: hidden !important;
        .info {
          display: block;
          float: left;
          width: calc(100% - 120px);
          .name {
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
            position: relative;
            display: block;
            text-transform: capitalize;
          }
          .details {
            font-size: 0.8em;
            overflow: hidden;
            .attr {
              float: left;
              margin-right: 0.3em;
              .attr-name {
                font-weight: bold;
              }
            }
          }
        }

        .dropdown-toggle::after {
          display: none;
        }
        .btn {
          margin: 0;
          padding: 0;
          background: transparent !important;
          position: relative;
          margin: 0;
          top: -2px;
          border: none;
          box-shadow: none !important;
          line-height: 1rem;
          margin-left: 0.3rem;
          opacity: 0;
        }

        .status {
          display: block;
          float: right;
          width: 90px;
          text-align: right;
          line-height: 1.2em;

          .speed {
            font-size: 0.8em;
            display: block;
            clear: both;
            text-align: right;
          }
        }

        .edit {
          display: block;
          float: left;
          width: 20px;
          text-align: right;
          font-size: 1.4em;
          line-height: 15px;
          opacity: 0;
        }
      }

      .dropright {
        position: unset;
        /*position: static;
        overflow: visible;
        /*position: absolute;
        z-index: 999999;
        right: 35px;*/
        &.show {
          .btn {
            opacity: 1;
          }
        }
        .dropdown-menu {
          /*position: absolute !important;
          z-index: 999999999;
          transform: none !important;
          left: 20px !important;*/
          //transform: translate3d(317px, 146px, 0px)!important;
          left: 10px!important;
          //top: 0px!important;
        }
      }

      &.selected {
        background: variables2.$secondary;
        .name {
          font-weight: bold;
        }
      }
      &:hover {
        background: variables2.$secondary;
        .edit {
          opacity: 1;
        }
        .btn {
          opacity: 1;
        }
      }
    }
  }
}
