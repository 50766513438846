@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes updated {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.updated {
    animation: updated 0.4s ease;
}



@keyframes gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

.gradient{
  animation:  gradient 10s ease infinite;
}