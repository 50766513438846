.events-list.list-group{
    padding: .5rem;
    .list-item{
        padding: 0;
        margin-bottom: .5rem;
        .alert{
            cursor: pointer;
            margin: 0;
            font-size: .8rem;
            padding: .5em 2em;
            .alert-heading{
                font-size: 1em;
                margin-bottom: 0;
            }
            .close{
                padding: 0;
                position: absolute;
                top: 50%;
                margin-top: -.5em;
                right: .5rem;
            }
        }
    }
}