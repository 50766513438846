@use "~bootstrap/scss/bootstrap.scss";

.ol-scale-line {
  bottom: 10px;
  left: auto;
  right: 10px;
  border-radius: 0;
  background: rgba(bootstrap.$white, 0.5);
  .ol-scale-line-inner {
    color: bootstrap.$black;
    border-color: bootstrap.$black;
  }
}

.ol-zoom {
  left: auto;
  right: 0;
  
}
