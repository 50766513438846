@use "~bootstrap/scss/bootstrap.scss";
@use "variables" as variables2;

.form-control {
  border-radius: 0;
  //border-width: 2px;
  border-color: #3333;
}

.btn {
  border-radius: 0;

  &.btn-xs {
    padding: 2px;
    font-size: 0.9em;
    line-height: 1em;
  }
}

.card {
  border-radius: 0;
  .card-header {
    border-radius: 0;
  }
}

.list-group {
  border-radius: 0;
  .list-group-item {
    border-radius: 0;
    position: unset;
    &:first-child {
      border-radius: 0;
    }
  }

  .list-group-item-action {
    padding: 0.3em;
    padding-left: 1em;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    .content {
      flex-grow: 1;
      .name {
        font-weight: bold;
        display: block;
        font-size: 1.1em;
      }
      .value {
        .attribute {
          display: inline-block;
          white-space: nowrap;
          .attr-name {
            display: inline-block;
            font-weight: 500;
            white-space: nowrap;
            margin-right: 0.3em;
          }
          .attr-value {
            display: inline-block;
            font-weight: 400;
            margin-right: 1em;
            white-space: nowrap;
          }
        }
        .attr-color {
          display: inline-block;
          padding: 0 0.5em;
        }
      }
    }
    .actions {
      flex-grow: 0;
      padding-left: 0.3rem;
      margin-left: 0.3rem;
      border-left: 1px solid bootstrap.$border-color;
      .btn {
        height: 100%;
        box-shadow: none !important;
        &.danger {
          color: bootstrap.$danger;
        }
      }
    }
  }
}

.list-group.t01 {
  border: none;
  border-radius: 0;
  .list-group-item {
    border-radius: 0;
    border: 0;
    //border-top: 1px solid $border-color;
    margin: 0;
    &:first-child {
      border-radius: 0;
      border-top: none;
    }
  }
}

.scrollable-menu {
  height: auto;
  max-height: 50vh;
  overflow-x: hidden;
  background: bootstrap.$white;
  border-radius: 0;
  min-width: 100%;
}
.scrollable-menu::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
}
.scrollable-menu::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: lightgray;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.75);
}

.alert {
  border-radius: 0;
}

.dropdown-menu {
  border-radius: 0;
}

.toggle-btn.active {
  background: bootstrap.$primary !important;
  color: bootstrap.$white !important;
  border-color: bootstrap.$primary !important;
}

.modal-content {
  border-radius: 0;
}

.navbar-nav {
  flex-direction: row;
  .nav-item {
    margin-left: 1em;
  }
}

.custom-modal {
  font-size: 0.8rem;
  margin: 0;

  .modal-dialog {
    max-height: 100%;
  }

  .modal-content {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
    flex-grow: 1;
    max-height: calc(100vh - 3.5em);
  }

  .modal-body {
    min-height: 250px;
    flex: 1;
    overflow: auto;

    &.visible {
      overflow: visible;
    }
  }

  &.form {
    .modal-body {
      min-height: auto;
    }
  }

  .modal-header {
    font-size: 0.8rem;
    padding: 0.5rem 0.8rem;
    line-height: 1em;
    background: bootstrap.$secondary;

    .modal-title {
      font-size: 1.2rem;
    }

    .close {
      outline: none;
    }
  }
  .modal-footer {
    font-size: 0.8rem;
    padding: 0.5rem 0.8rem;
    .btn {
      font-size: 0.8rem;
    }
  }
  .form-label {
    font-weight: bold;
  }
  .form-group {
    font-size: 0.8rem;
    margin-bottom: 0.5em;
  }
  .form-control {
    font-size: 0.8rem;
  }

  &.full-screen {
    @media screen and (max-width: variables2.$mobile-break) {
      padding: 0 !important;
      margin: 0;
      .modal-dialog {
        display: flex;
        padding: 0;
        margin: 0;
        flex-direction: column;
        min-height: 100%;
        min-width: 100%;
        &:before {
          display: none;
        }
        .modal-content {
          flex-grow: 1;
          display: flex;
          max-height: 100%;
        }
      }
    }
  }
}

.rbt-menu {
  .dropdown-item {
    font-size: 0.8em;
  }
}

.table {
  .td-controls {
    width: 50px;
    .controls {
      right: 0;
      display: flex;
      .btn {
        padding: 0 0.125em;
        margin: 0;
        height: 1.5em;
        margin-left: 0.3em;
        box-shadow: none !important;
      }
    }
  }
}

.custom-number-field {
  display: flex;
  flex-direction: row;
  .field {
    flex-grow: 1;
  }
  .unit {
    flex-grow: 0;
    line-height: 1em;
    padding: 0.75em 0.5em;
    .unit-options {
      margin-top: -0.75em;
    }
  }
}

.hamburger {
  padding: 0.25rem;
  outline: none !important;
  width: 2.5rem;
  .hamburger-box {
    width: 2rem;
  }
  .hamburger-inner,
  .hamburger-inner:after,
  .hamburger-inner:before {
    background: bootstrap.$black;
    width: 2rem;
    height: 0.1rem;
  }
  &.is-active {
    .hamburger-inner,
    .hamburger-inner:after,
    .hamburger-inner:before {
      background: bootstrap.$black;
      width: 2rem;
    }
  }
}

.overflow {
  overflow: auto;
}

.form.form-sm {
  font-size: 0.8rem;
  .form-label {
    font-weight: bold;
  }
  .form-control {
    font-size: 0.8rem;
  }

  .btn {
    font-size: 0.8rem;
    &:first-child {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: variables2.$mobile-break) {
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl,
  .col-xl-auto {
    padding-right: 7px;
    padding-left: 7px;

    &:first-child {
      padding-left: 15px;
    }
    &:last-child {
      padding-right: 15px;
    }
  }

  .col-12 {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.custom-select-component {
  border-radius: 0;
  .custom-select__control,
  .custom-select__control:hover {
    border-radius: 0;
    border-color: bootstrap.$border-color;
    //box-shadow: 0 0 0 0.2rem rgba(1, 160, 226, 0.25);
    box-shadow: none;
    min-height: auto;
    &.custom-select__control--is-focused {
      box-shadow: 0 0 0 0.2rem rgba(1, 160, 226, 0.25);
      border-color: bootstrap.$primary;
    }
  }

  .custom-select__menu {
    border-radius: 0 !important;
    .custom-select__menu-list {
      border-radius: 0 !important;
      .custom-select__option--is-focused {
        background: bootstrap.$secondary;
      }
      .custom-select__option--is-selected {
        background: bootstrap.$primary !important;
      }
    }
  }
  .custom-select__indicator {
    padding: 4px !important;
  }

  &.custom-select-margin-50 {
    .custom-select__menu {
      margin-top: -40px;
    }
  }
}

.flex-grow-1 {
  flex: 1;
}

.h-100 {
  height: 100% !important;
  min-height: 100% !important;
}

.container {
  height: 100% !important;
  min-height: 100% !important;
  width: 100% !important;
}

.category-options {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  max-width: 100%;
  margin: 0 auto;
  flex-wrap: wrap;
  .category-option {
    cursor: pointer;
    display: inline-block;
    width: 100px;
    padding: 0.5rem;
    margin: 0.5rem;
    border: 1px solid bootstrap.$secondary;
    &:hover {
      background: bootstrap.$secondary;
    }

    &.selected {
      background: bootstrap.$primary;
    }
  }
}

.link {
  text-decoration: underline !important;
  cursor: pointer;
}

.table-fixed {
  position: relative;
  background: #fff;
  padding-top: 30px;
  font-size: 0.8em;
  max-height: 100%;
  height: 100%;

  >div{
    max-height: 100%;
    height: 100%;
  }

  .react-bootstrap-table-pagination{
    border-top: 1px solid bootstrap.$border-color;
    >:first-child{
      display: none;
    }
    >div{
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      flex: none;
      max-width: none;
      width: 100%!important;
      display: flex;
      justify-content: center;
      .pagination{
        margin: .5rem 0;
        .page-item .page-link{
          border-radius: 0!important;
          box-shadow: none!important;
          font-size: .8em;
        }
      }
    }
  }
  .react-bootstrap-table {
    height: calc(100% - 50px);
    overflow: auto;
    table.table {
      border-spacing: 0;
      width: 100%;
      
      thead {
        border-bottom: 1px solid bootstrap.$border-color;
      }

      td + td {
        border-left: 1px solid #eee;
      }
      td,
      th {
        border-bottom: 1px solid #eee;
        color: #000;
        padding: 0 0.5rem;
      }
      th {
        height: 0;
        line-height: 0;
        padding-top: 0;
        padding-bottom: 0;
        color: transparent;
        border: none;
        white-space: nowrap;
      }
      th div {
        position: relative;
        background: transparent;
        padding: 0 0.5rem;
        top: 0;
        margin-left: -8px;
        line-height: normal;
        border-left: 1px solid bootstrap.$border-color;
        color: #000;
        height: 30px;
        vertical-align: middle;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
      }
      th:first-child div {
        border: none;
      }
    }
  }
}