@use "~bootstrap/scss/bootstrap.scss";
@use "../variables" as variables2;

.page-component {
  @keyframes translate {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  position: fixed;
  top: 3rem;
  left: 0;
  right: 0;
  bottom: 0;
  background: bootstrap.$white;
  z-index: 5;
  transform: translateX(100%);
  transition: translate 0.3s ease-in;
  &.open {
    transform: translateX(0);
  }

  .wrapper {
    background: linear-gradient(135deg, hsla(337.39, 90.38%, 48.53%, 1) 0%, hsla(337.39, 90.38%, 48.53%, 0) 70%),
      linear-gradient(25deg, hsla(47.17, 93.66%, 45.14%, 1) 10%, hsla(47.17, 93.66%, 45.14%, 0) 80%),
      linear-gradient(315deg, hsla(274.33, 94.45%, 41.83%, 1) 15%, hsla(274.33, 94.45%, 41.83%, 0) 80%),
      linear-gradient(245deg, hsla(271.24, 92.04%, 47.97%, 1) 100%, hsla(271.24, 92.04%, 47.97%, 0) 70%);
    background-size: 400% 400%;
    animation: gradient 10s ease infinite;

    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;

    .page-component {
      top: 0;
    }

    > .body {
      flex-grow: 1;
      overflow: auto;
      height: 90%;
      max-width: 1140px;
      margin: 0 auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      overflow: visible;
    }

    .internal-title {
      display: none;
    }

    .container-wrapper {
      position: static;
      max-height: 100%;
      display: flex;
      margin: 0 auto;
      background: #fff;
      width: 100%;
      flex-grow: 1;
      height: 100%;
      &.grow {
        flex: 1 1;
      }
      .container {
        max-width: 100% !important;
        padding-bottom: 2em;
        height: auto !important;
      }
    }

    .footer {
      border-top: 1px solid variables2.$secondary;
      padding: 1em 0;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      background: "#ffffff";
      .container {
        padding-bottom: 0em;
      }
      .btn {
        margin-left: 1em;
      }
    }
    .header-small {
      padding: 1em 0;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      background: bootstrap.$primary;
      >.container  {
        >.nav-tabs{
          >.nav-item {
            color: #111111;
          }
        }
        
      }
      
    }

    > .header {
      height: 3rem;
      //background: $secondary;
      padding: 0 3rem 0 0.75rem;
      position: relative;
      //border-bottom: 1px solid $border-color;
      flex-grow: 0;
      color: bootstrap.$white;

      > .title {
        font-size: 1.5rem;
        font-weight: normal;
        line-height: 3rem;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      > .close {
        position: absolute;
        width: 3rem;
        height: 3rem;
        background: none;
        top: 0;
        right: 0;
        outline: none;
        cursor: pointer;
        z-index: 1;
        color: bootstrap.$white;
      }
    }

    .list-tools {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      .list-controls {
        flex-grow: 0;
        display: block;
        position: relative;
        padding: 0px;
        overflow: hidden;
        height: 30px;
        float: left;
      }
      .filter {
        flex-grow: 1;
        float: left;
        margin: 3px 3px 1rem 3px; 
        .form-group {
          margin: 0;
        }
      }
      hr {
        clear: both;
      }
    }

    .toolbar {
      justify-content: end;
      .container {
        padding: 0 !important;
        margin-bottom: 1em;
      }
    }
  }

  @media screen and (min-width: variables2.$mobile-break) {
    top: 0;
    .wrapper {
      background-image: url("/img/bg-02.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      animation: none;
      .body {
        padding: 2em;
      }

      .internal-title {
        display: block;
        font-size: 1.8em;
        margin-bottom: 0.8em;
        padding-bottom: 0.2em;
        border-bottom: 1px solid bootstrap.$border-color;
        font-weight: 400;
      }

      .container-wrapper {
        max-height: 100%;
        display: flex;
        margin: 0 auto;
        background: #fff;
        width: 100%;
        //border-radius: 5px;
        flex-grow: 0;
        height: auto;
        box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.5) !important;
        &.grow {
          flex: 1 1;
        }
      }
      > .header {
        padding: 0.25rem 15rem 0 15rem;
        > .title {
          text-align: center;
        }

        > .close {
          right: 12.5rem;
          top: 0.25rem;
        }
      }
      .footer {
        //border-radius: 0 0 5px 5px;
      }
    }
  }
}
