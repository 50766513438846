#pgLogin{
    .login-form{
        max-width: 500px;
        position: relative;

    }
    .btn-dark {
        color: #fff;
        background-color: #000000!important;
        border-color: #000000!important;

    }
    .bg-dark {
        background-color: #000000 !important;
    }
    img {

    }
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding-right: 250px;
}

.login-container {
  align-items: center !important;
  max-width: 100% !important;
  justify-content: space-evenly;
}

.login-title {
  margin-bottom: 15% !important;
}

.login-card {
  margin-left: 30%;
  background-color: white;
  max-width: 510px !important;
  min-width: 510px !important;
  width: 510px !important;
  padding-bottom: 2rem !important;
  padding-top: 1rem !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.login-logo {
  width: 25rem;
}

.container-padding {
  padding-left: 2rem!important;
  padding-right: 2rem!important;
}

@media (max-width: 1450px) {
  .login-card {
    margin-left: 10%;
  }
}

@media (max-width: 957px) {
  .login-card {
    margin-left: 0;
  }
}

@media (max-width: 1172px) {
  .login-container {
    justify-content: center;
  }
}

@media (max-width: 600px) {
  .login-container {
    margin: 0 !important;
    align-items: end !important;
  }
  .login-logo {
    width: 15rem;
  }
  .login-card {
    max-width: 100% !important;
    min-width: 100% !important;
    width: 100% !important;
    padding-bottom: 2rem !important;
  }
  .container-padding {
    padding-left: 0!important;
    padding-right: 0!important;
  }
  .form {
    width: 100% !important;
  }
}

.pt-2 {
  display: block;
  margin-left: auto;
  margin-right: auto;

}

.welcome {
  font-size: 3rem;
  color: white;
  text-align: center;
  font-weight: 600;
}

.business {
  font-size: 2.8rem;
  color: #ffffff;
  text-align: center;
}
