@use "~bootstrap/scss/bootstrap.scss";

.connection-status{
    position: fixed;
    top: 1rem;
    left: 50%;
    margin-left: -50px;
    width: 100px;
    z-index: 4;
    background: bootstrap.$danger;
    text-align: center;
    color: ligthen(bootstrap.$danger, .5);
    color: bootstrap.$white;
    font-size: .8rem;
    opacity: 1;
    transition: opacity .5s linear;
    

    &.online{
        background: bootstrap.$success;
        opacity: 0;
        transition: opacity 2s linear;
    }

    &.connecting{
        background: bootstrap.$warning;
    }
}
