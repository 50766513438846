.loading-overlay{
    position: fixed;
    top: 3rem;
    left: 0;
    bottom: 0;
    right: 0;
    margin-top: -1px;
    background:rgba(0, 0, 0, .3);
    z-index: 200;
    display: none;
    opacity: 0;
    transition: opacity .3s linear;

    .loader{
        width: 120px;
        height: 120px;
        background: transparent;
        display: block;
        position: fixed;
        top: 50%;
        left: 50%;
        margin-left: -25px;
        margin-top: -25px;
        background: url('/img/loader-02.svg') center center transparent;
        background-size: 120px 120px;
    }
}

.show-loading-overlay {
    .loading-overlay{
        display: block;
        opacity: 1;
    }
}